/* eslint-disable lingui/no-unlocalized-strings */
// Mui
import { alpha, type ThemeOptions } from '@mui/material';
import { mobileLayoutBreakpoint } from '..';

import { brandingColors as siteBrandingColors } from './site';

// Default branding
const defaultBrandingColors: BrandingColors = {
  premiums: {
    light: '#336F8B',
    main: '#336F8B',
    dark: '#336F8B',
  },
  hsa: {
    light: '#e49e84',
    main: '#E17E5B',
    dark: '#C65300',
  },
  hsa_and_lpfsa: {
    light: '#e49e84',
    main: '#E17E5B',
    dark: '#C65300',
  },
  hsaPrincipal: {
    light: '#f0c9b8',
    main: '#ecbfad',
    dark: '#E17E5B',
  },
  hsaGains: {
    light: '#fdf7f5',
    main: '#faece7',
    dark: '#E17E5B',
  },
  fsa: {
    light: '#7e5380',
    main: '#551B57',
    dark: '#551B57',
  },
  lpfsa: {
    light: '#c46e9c',
    main: '#B73F7C',
    dark: '#B73F7C',
  },
  oop: {
    light: '#59b1bf',
    main: '#0C97A9',
    dark: '#0C97A9',
  },
  estimatedCostTotal: {
    light: '#bd5b8f',
    main: '#b13d7b',
    dark: '#b13d7b',
  },
  estimatedCostNet: {
    light: '#4facba',
    main: '#0C97A9',
    dark: '#0C97A9',
  },
  taxSavings: {
    light: '#a4d3d9',
    main: '#99cbd2',
    dark: '#0C97A9',
  },
  isGood: {
    light: '#567F2D',
    main: '#567F2D',
    dark: '#567F2D',
  },
};

// Custom branding
const brandingColors = { ...defaultBrandingColors, ...siteBrandingColors };

const modalHeaderColor = '#551B57';
const toolTipBorderColor = ' #a6a6a6';
const borderGreyColor = '#E5E5E5';
const borderLightGreyColor = '#F2F2F2';
const progressBackgroundGrey = '#D9D9D9';
const darkGreyBackground = '#E6E6E6';
const blueBackground = '#E8F4F8';
const lightGreyBackground = '#F9F9F9';
const moneyGreen = '#3E7C00';
//const lightGreyText = '#727272';

// Goal colors
const healthGoalColor = '#C65300';
const healthGoalBackgroundColor = '#FCF6F2';
const healthGoalStripesDark = '#FBEBE5';
const healthGoalStripesLight = '#FDF7F4';
const healthGoalRetirementStripesDark = '#F1BEAD';
const healthGoalRetirementStripesLight = '#F1BEAD';

const emergencyGoalColor = '#0C97A9';
const emergencyGoalBackgroundColor = '#F3FAFB';
const emergencyGoalStripesDark1 = '#DBEFF2';
const emergencyGoalStripesLight1 = '#EEF9FA';
const emergencyGoalStripesDark2 = '#AFDEE3';
const emergencyGoalStripesLight2 = '#D3F0F4';
const emergencyGoalStripesDark3 = '#66BDC9';
const emergencyGoalStripesLight3 = '#8EDDE5';

const retirementGoalColor = '#773C77';
const retirementGoalBackgroundColor = '#F3EDF3';
const retirementGoalStripesDark = '#ECE3EC';
const retirementGoalStripesLight = '#F7F3F7';

// Mui default - Light Colors
export const paletteLight: ThemeOptions['palette'] = {
  primary: {
    main: 'blue',
  },
};

// Mui default - Dark Colors
export const paletteDark: ThemeOptions['palette'] = {};

export const roundedBottomBorderSx = (color: string) => ({
  content: '""',
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: color,
  height: '4px',
  borderRadius: '20px',
  border: 'none !important',
});

export const focusState = (radius: number) => ({
  position: 'relative',
  '&:before': {
    content: '""',
    position: 'absolute',
    left: '-3px',
    top: '-3px',
    right: '-3px',
    bottom: '-3px',
    boxShadow: '0px 0px 5px 0px #00AEDF',
    borderRadius: `${radius || 2}px`,
  },
});

export const nextDollarCardColor = (
  color: string,
  backgroundColor: string,
) => ({
  '& button.details-button': {
    color,
    py: 0,
    px: 1.25,
    minHeight: 0,
    '&.MuiButton-contained': {
      backgroundColor,
      borderColor: backgroundColor,
    },
    '&.MuiButton-outlined': {
      borderColor: color,
      '&:hover': {
        backgroundColor: color,
        color: '#ffffff',
      },
    },
  },
  '& span.bold': {
    color,
  },
  '& a': {
    color,
    textDecorationColor: color,
  },
  '& svg.percent-chart': {
    stroke: color,
    '& text': {
      fill: color,
    },
  },
  '& .incomplete-goal-icon': {
    backgroundColor: color,
    color: '#ffffff',
    borderRadius: '50%',
    padding: '18px',
    '& svg': {
      display: 'block',
    },
  },
  '& table td': {
    p: 1,
  },
  '& table td p.debit': {
    color: moneyGreen,
    fontWeight: 700,
  },
  '& table tr.shaded': {
    background: lightGreyBackground,
  },
  '& table tr.shaded td, & table tr.shaded p': {
    fontWeight: 700,
  },
  '& table tr.shaded.highlight': {
    background: backgroundColor,
  },
  '& table tr.highlight td, & table tr.highlight p': {
    color,
    fontWeight: 700,
  },
});

export const nextDollarProjectionsColor = (color: string) => ({
  '& span.highlight': {
    color,
  },
  '& .nextdollar--projection-content .bold': {
    color,
  },
});

export const nextDollarChartColor = (
  color: string,
  stripes: {
    id: string;
    light: string;
    dark: string;
  }[],
) => {
  const styles: { [key: string]: any } = {
    '& .chart-title': {
      color,
      fontWeight: 600,
    },
    '& svg .covered-line, & svg .covered-circle': {
      stroke: color,
    },
  };

  stripes.forEach((s) => {
    styles[`& svg pattern#pattern-stripe-${s.id}`] = {
      '& rect.background-stripe': {
        fill: s.light,
      },
      '& rect.foreground-stripe': {
        fill: s.dark,
      },
    };
  });
  return styles;
};

export type BrandingColor = {
  light: string;
  main: string;
  dark: string;
};

export type BrandingColors = {
  premiums: BrandingColor;
  hsa: BrandingColor;
  hsa_and_lpfsa: BrandingColor;
  hsaPrincipal: BrandingColor;
  hsaGains: BrandingColor;
  fsa: BrandingColor;
  lpfsa: BrandingColor;
  oop: BrandingColor;
  estimatedCostTotal: BrandingColor;
  estimatedCostNet: BrandingColor;
  taxSavings: BrandingColor;
  isGood: BrandingColor;
};

// Util
const vignetteStyles = (
  color: BrandingColor,
  options: {
    isStripe?: boolean;
  } = {
    isStripe: true,
  },
) => ({
  color: color.main,
  fill: color.main,
  '&:before': {
    color: color.main,
    fill: color.main,
  },
  ' .covered-line': {
    stroke: color.main,
  },
  ' .covered-circle': {
    stroke: color.main,
  },
  ...(options?.isStripe && {
    '& .background-stripe': {
      fill: color.light || `${color.main}33`,
    },
    '& .foreground-stripe': {
      fill: color.main,
    },
  }),
});

// Mui - Granular Overrides
export const components: ThemeOptions['components'] = {
  MuiCssBaseline: {
    styleOverrides: (theme) =>
      theme.unstable_sx({
        body: {
          ' .branding': {
            '&-vignette': {
              '&-hsa': vignetteStyles(brandingColors.hsa),
              '&-hsa_and_lpfsa': vignetteStyles(brandingColors.hsa_and_lpfsa),
              '&-premiums': vignetteStyles(brandingColors.premiums, {
                isStripe: false,
              }),
              '&-oop': vignetteStyles(brandingColors.oop),
              '&-fsa': vignetteStyles(brandingColors.fsa),
              '&-lpfsa': vignetteStyles(brandingColors.lpfsa),
              '&-growth-principal': vignetteStyles(brandingColors.hsaPrincipal),
              '&-growth-total': vignetteStyles(brandingColors.hsa),
              '&-growth-gains': vignetteStyles(brandingColors.hsaGains),
              '&-cost-total': vignetteStyles(brandingColors.estimatedCostTotal),
              '&-cost-total-header': {
                color: brandingColors.estimatedCostTotal.dark,
              },
              '&-cost-net': vignetteStyles(brandingColors.estimatedCostNet),
              '&-cost-net-gain': {
                color: brandingColors.isGood.main,
              },
              '&-tax-savings': vignetteStyles(brandingColors.taxSavings),
              '&-tax-savings-title': {
                color: brandingColors.taxSavings.dark,
              },
              '&-estimated-cost-total': {
                backgroundColor: `${brandingColors.estimatedCostTotal.main}10`,
                '  .MuiTypography-root': {
                  color: brandingColors.estimatedCostTotal.main,
                },
              },
              '&-estimated-cost-net': {
                backgroundColor: `${brandingColors.estimatedCostNet.main}10`,
                '  .MuiTypography-root': {
                  color: brandingColors.estimatedCostNet.main,
                },
              },
            },
          },
          // *** Re-skin Typography Styles ***
          ' .bold': {
            fontWeight: 700,
          },
          ' .small': {
            fontSize: '0.75rem',
            lineHeight: '120%',
          },
          ' .mediumSmall': {
            fontSize: '0.875rem',
            lineHeight: '120%',
          },
          ' .callOut': {
            color: 'primary.main',
          },
          ' .emphasized': {
            fontStyle: 'italic',
          },
          ' .appBody': {
            zIndex: 1,
            '&:after': {
              content: '" "',
              display: 'flex',
              position: 'absolute',
              width: '100%',
              height: '100%',
              maxHeight: 500,
              zIndex: -1,
            },
          },
          ' .appFooter': {
            background: theme.palette.background.default,
          },
          ' .tooltipTextButton.pseudoLink': {
            textDecoration: 'underline',
            textDecorationStyle: 'dashed',
            minWidth: 0,
            '&:hover': {
              textDecorationStyle: 'solid',
            },
          },
          ' .tabProgressBackground': {
            backgroundColor: progressBackgroundGrey,
          },
          ' .staticSlider': {
            fill: theme.palette.primary.main,
            stroke: theme.palette.primary.main,
          },
          ' .bubblesContainer': {
            borderRadius: 2,
            backgroundColor: {
              xs: 'background.default',
              [mobileLayoutBreakpoint]: blueBackground,
            },
          },
          ' .loadingReview': {
            backgroundColor: {
              xs: 'background.default',
              [mobileLayoutBreakpoint]: blueBackground,
            },
          },
          ' .specialUsageChange': {
            color: 'secondary.main',
          },
          ' .specialUsageIsPreset': {
            fontSize: '21px',
            backgroundColor: blueBackground,
            fontWeight: 'bold',
            pb: '2px',
          },
          ' .mobileTableHeader': {
            backgroundColor: lightGreyBackground,
            textTransform: 'uppercase',
            fontWeight: 700,
          },
          ' .selectedBundle': {
            color: 'primary.main',
            '& .divider-highlighted': {
              borderColor: 'primary.main',
            },
          },
          ' .summaryPlanCard.selected': {
            border: `2px solid ${theme.palette.primary.main}`,
          },
          ' .vignette': {
            width: '100%',
            maxWidth: '100%',
            display: 'none',
            flexDirection: 'column',
            '&-active': {
              display: 'flex',
            },
            '&-legal': {
              maxHeight: 80,
              overflow: 'auto',
              mt: 4,
              pr: 2,
              ' .MuiTypography-root': {
                fontSize: '0.75rem',
                color: 'text.secondary',
                mb: 2,
              },
            },
            '&-compare': {},
            '&-growth': {},
            '&-cost': {},
            '&-tax': {},
          },
          ' .stepper': {
            '& .contained': {
              fill: theme.palette.primary.main,
              stroke: theme.palette.primary.main,
              '&.simple': {
                fill: theme.palette.secondary.main,
                stroke: `${theme.palette.secondary.main}60`,
                strokeWidth: 2,
              },
            },
            '&.light .outlined': {
              fill: 'rgba(0,0,0,0)',
              stroke: theme.palette.background.paper,
              '&.simple': {
                fill: borderLightGreyColor,
                stroke: borderGreyColor,
                strokeWidth: 2,
              },
            },
            '&.dark .outlined': {
              fill: 'rgba(0,0,0,0)',
              stroke: theme.palette.secondary.main,
              '&.simple': {
                fill: borderLightGreyColor,
                stroke: borderGreyColor,
                strokeWidth: 2,
              },
            },
          },
          ' .bundle-print-view': {
            ' .vignette-controls': {
              display: 'none',
            },
            ' .vignette-legal': {
              maxHeight: 'initial',
            },
          },
          ' .shaded': {
            backgroundColor: lightGreyBackground,
          },
          ' .debit': {
            color: '#00ADEF',
          },

          ' div:focus, h1:focus, h2:focus, h3:focus': {
            outline: 'none',
          },
          ' #returning-user-greeting': {
            ' .user-progress': {
              backgroundColor: lightGreyBackground,
              ' > .MuiBox-root': {
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                marginInline: 3,
                color: 'GrayText',
              },
              ' .active': {
                color: theme.palette.primary.main,
              },
            },
          },
          'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
            {
              WebkitAppearance: 'none',
              margin: 0,
            },
          ' .estimated-cost-table': {
            '& th, & td': {
              borderLeft: 'none',
              borderRight: 'none',
              py: {
                xs: 1,
                sm: 1.5,
              },
              px: 0.5,
            },
            '& tr:first-of-type th': { borderTop: 'none' },
            ' .MuiTableCell-root': {
              '&:first-of-type': {
                paddingLeft: theme.spacing(1.5),
              },
              '&:last-of-type': {
                paddingRight: theme.spacing(1.5),
              },
            },
            ' .effort-label': {
              fontWeight: 'bold',
            },
          },
        },
        ' .goal-chip': {
          py: 0.25,
          px: 1,
          fontWeight: 700,
          display: 'inline-block',
          borderRadius: '100px',
          '&.healthcare': {
            backgroundColor: healthGoalColor,
            color: '#ffffff',
          },
          '&.emergencyfund': {
            backgroundColor: emergencyGoalColor,
            color: '#ffffff',
          },
          '&.retirement': {
            backgroundColor: retirementGoalColor,
            color: '#ffffff',
          },
        },
        ' .nextdollar--sliderNoZone': {
          background: `repeating-linear-gradient( -45deg, ${theme.palette.secondary.main} 0px, ${theme.palette.secondary.main} 4px, #2794BC 4px, #2794BC 8px)`,
        },
        ' .nextdollar--goal-card': {
          backgroundColor: 'background.paper',
          borderColor: borderGreyColor,
          borderStyle: 'solid',
          borderWidth: '1px',
          boxShadow: 'none',
          borderRadius: '12px',
          '& span.bold': {
            fontWeight: 700,
          },
          '& button': {
            textDecoration: 'none',
            fontWeight: 700,
          },
          '&.goal-healthcare': nextDollarCardColor(
            healthGoalColor,
            healthGoalBackgroundColor,
          ),
          '&.goal-emergencyfund': nextDollarCardColor(
            emergencyGoalColor,
            emergencyGoalBackgroundColor,
          ),
          '&.goal-retirement': nextDollarCardColor(
            retirementGoalColor,
            retirementGoalBackgroundColor,
          ),
        },
        ' .nextdollar--projection': {
          '&.goal-healthcare': nextDollarProjectionsColor(healthGoalColor),
          '&.goal-emergencyfund':
            nextDollarProjectionsColor(emergencyGoalColor),
          '&.goal-retirement': nextDollarProjectionsColor(retirementGoalColor),
        },
        ' .trend-chart': {
          '&.trend-chart--healthcare': nextDollarChartColor(healthGoalColor, [
            {
              id: 'primary',
              light: healthGoalStripesLight,
              dark: healthGoalStripesDark,
            },
            {
              id: 'retirement',
              light: healthGoalRetirementStripesLight,
              dark: healthGoalRetirementStripesDark,
            },
          ]),
          '&.trend-chart--retirement': nextDollarChartColor(
            retirementGoalColor,
            [
              {
                id: 'primary',
                light: retirementGoalStripesLight,
                dark: retirementGoalStripesDark,
              },
            ],
          ),
          '&.trend-chart--emergencyfund': nextDollarChartColor(
            emergencyGoalColor,
            [
              {
                id: 'tier-1',
                light: emergencyGoalStripesLight1,
                dark: emergencyGoalStripesDark1,
              },
              {
                id: 'tier-2',
                light: emergencyGoalStripesLight2,
                dark: emergencyGoalStripesDark2,
              },
              {
                id: 'tier-3',
                light: emergencyGoalStripesLight3,
                dark: emergencyGoalStripesDark3,
              },
            ],
          ),
          '& svg .event-connector': {
            '&.healthcare': { stroke: healthGoalColor },
            '&.emergencyfund': {
              stroke: emergencyGoalColor,
            },
            '&.retirement': { stroke: retirementGoalColor },
          },
          '& .event-chip': {
            borderRadius: '100px',
            color: 'text.primary',
            fontWeight: 600,
            textAlign: 'center',
            maxWidth: '200px',
            py: 0.5,
            px: 1,
            '&.event--healthcare': {
              backgroundColor: healthGoalBackgroundColor,
            },
            '&.event--emergencyfund': {
              backgroundColor: emergencyGoalBackgroundColor,
            },
            '&.event--retirement': {
              backgroundColor: retirementGoalBackgroundColor,
            },
          },
          '& .x-axis text, & .y-axis text': {
            fontSize: 12,
            fill: '#737373',
            fontWeight: 600,
          },
        },
        ' .learnmore-accordion': {
          '& .MuiAccordionSummary-root': {
            backgroundColor: borderLightGreyColor,
          },
        },
        ' table.learnmore': {
          '& tr': {
            borderBottom: `1px solid ${borderGreyColor}`,
            '&:last-child': {
              borderBottom: 'none',
            },

            '&.total': {
              backgroundColor: borderLightGreyColor,
              '& td, & th': {
                fontWeight: 700,
              },
            },
          },
          '& th': {
            border: 'none',
            padding: 1,
            fontSize: '1rem',
          },
          '& td': {
            border: 'none',
            padding: 1,
            fontSize: '1rem',
            whiteSpace: 'nowrap',
            textAlign: 'right',
            '&.add': {
              color: moneyGreen,
            },
          },
        },
        ' .product-tour--dialog': {
          overflow: 'hidden',
          borderRadius: '10px',
          '& .dialogContent': {
            backgroundColor: 'background.paper',
          },
          '& .dialogHeader': {
            color: 'text.primary',
            '& .MuiIconButton-root': {
              color: 'text.primary',
            },
            p: 2,
          },
          '& .dialogDescription': {
            m: 0,
            px: 3,
            py: 2,
          },
          '& .dialogViewport': {
            boxShadow: `2px 2px 7px 2px rgba(0, 0, 0, 0.10) inset, 0 0 0 ${theme.spacing(
              5,
            )} ${theme.palette.background.paper}`,
            borderRadius: '5px',
          },
        },
        ' .themed-row': {
          color: 'primary.main',
          backgroundColor: `${theme.palette.primary.main}0D`,
        },
        ' .money-green': {
          color: moneyGreen,
        },
        ' .bundle-summary-tooltip--total': {
          backgroundColor: lightGreyBackground,
          borderTop: `2px solid ${borderGreyColor}`,
        },
      }),
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        h1: 'p',
        h2: 'p',
        h3: 'p',
        subtitle1: 'p',
      },
    },
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          '&.MuiTypography-gutterBottom': { mb: 2 },
        }),
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          '&>.MuiCardActionArea-root.Mui-focusVisible': focusState(5),
          '&.callout': {
            boxShadow: 'none',
            backgroundColor: {
              xs: darkGreyBackground,
              [mobileLayoutBreakpoint]: 'background.default',
            },
            '&.secondary': {
              backgroundColor: {
                [mobileLayoutBreakpoint]: blueBackground,
              },
            },
            '&.colored': {
              backgroundColor: {
                [mobileLayoutBreakpoint]: blueBackground,
              },
              '& .icon': {
                color: theme.palette.secondary.main,
              },
            },
            borderRadius: '8px',
            '& svg.icon': {
              color: 'primary.main',
            },
          },

          '&.MuiMenu-paper': {
            overflow: 'auto !important',
          },
          overflow: 'visible !important',
        }),
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        fontSize: '1.5rem',
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        verticalAlign: 'inherit',
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          '&.radio': {
            ml: -1,
          },
          '&.Mui-focusVisible': focusState(30),
        }),
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          color: 'text.primary',
          pb: 1,
          '&.MuiInputLabel-sizeSmall': {
            fontSize: 12,
          },
          '&.Mui-focused': {
            color: 'text.primary',
          },
          '&.Mui-disabled': {
            color: 'text.primary',
          },
        }),
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          '& .MuiTypography-root': {
            color: '#727272',
            zIndex: 10,
          },
          mr: 0,
        }),
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: 0,
        '&.Mui-selected': {
          backgroundColor: alpha(theme.palette.secondary.main, 0.1),
          '&:hover, &.Mui-focusVisible': {
            backgroundColor: alpha(theme.palette.secondary.main, 0.2),
          },
        },
      }),
    },
  },
  MuiInput: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          '&.Mui-focused': {
            borderRadius: '5px',
            ...focusState(5),
          },
          '&.inline .MuiSelect-standard': {
            minHeight: 0,
            lineHeight: 1.25,
            paddingY: 0,
          },
          '&.Mui-focused .MuiSelect-select': {
            backgroundColor: `rgba(0,0,0,0)`,
          },
          '&.MuiInput-underline:not(.Mui-focused):before':
            roundedBottomBorderSx(theme.palette.primary.main),
          '&.MuiInput-underline.inline:before': {
            content: 'none',
          },
          '&.MuiInput-underline.inline:after': {
            content: 'none',
          },
          '&.MuiInput-underline:after': roundedBottomBorderSx(
            theme.palette.primary.main,
          ),
        }),
    },
  },
  MuiOutlinedInput: {
    defaultProps: {
      fullWidth: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderRadius: '5px',
          borderWidth: '2px',
          borderColor: theme.palette.secondary.main,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderWidth: '2px',
        },
        backgroundColor: theme.palette.background.paper,
        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          backgroundColor: theme.palette.background.default,
        },
        '&.nextdollar--frequency-selector': {
          borderRadius: '0 5px 5px 0',
          marginLeft: '-1px',
          background: 'linear-gradient(180deg, #FFF 41%, #E6E6E6 100%)',
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '0 5px 5px 0',
          },
          '& .MuiSelect-select': {
            height: '2.25em',
            lineHeight: '2.25em',
          },
        },
        '&.nextdollar--budget-selector': {
          borderRadius: '5px 0 0 5px',
          fontWeight: '700',
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '5px 0 0 5px',
          },
          '& input': {
            height: '2.25em',
          },
        },
      }),
      input: ({ theme }: any) =>
        theme.unstable_sx({
          textAlign: 'left',
          py: 0,
          px: 2,
          '&.Mui-disabled': {
            color: theme.palette.grey[500],
            textFillColor: theme.palette.grey[500],
            zIndex: 100,
          },
        }),
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          mx: 0,
          mt: 0.5,
          fontSize: '0.85rem',
          lineHeight: 1.25,
          textAlign: { xs: 'center', [mobileLayoutBreakpoint]: 'left' },
        }),
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        listStyle: 'disc',
        '&:not(.MuiMenu-list)': {
          paddingLeft: 21,
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          display: 'list-item',
          pl: 0,
          py: 0,
          mb: 0.5,
        }),
    },
  },
  MuiListItemText: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          m: 0,
        }),
    },
  },
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      disableFocusRipple: true,
      color: 'secondary',
      size: 'medium',
      variant: 'contained',
    },
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          borderRadius: '30px',
          minHeight: theme.spacing(4),
          lineHeight: 1.25,
          letterSpacing: 'inherit',
          '&.Mui-focusVisible': focusState(30),
          '&.MuiButton-sizeLarge': {
            borderRadius: '35px',
            px: theme.spacing(6),
            minHeight: theme.spacing(7),
          },
        }),
      contained: ({ theme, ownerState }: any) =>
        theme.unstable_sx({
          px: 3,
          py: 0.5,
          alignItems: 'center',
          backgroundColor: `${ownerState.color}.main`,
          boxShadow: 'none',
          border: `1px solid ${theme.palette[ownerState.color].main}`,
          fontWeight: '600',
          '&.stepFormNextButton': {
            minWidth: '220px',
          },
          '&:hover': {
            backgroundColor: `${ownerState.color}.dark`,
            boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.4)',
          },
          '&.highlighted': {
            boxShadow: `0px 0px 5px 0px ${
              theme.palette[ownerState.color].main
            }`,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.grey[700],
            border: `1px solid ${theme.palette.grey[700]}`,
            color: theme.palette.getContrastText(theme.palette.grey[700]),
            opacity: 0.8,
          },
          '.buttonGroupContainer &': {
            px: 1,
            borderRadius: '10px',
            border: 'none',
            '&.unselected': {
              backgroundColor: theme.palette.grey[200],
              color: theme.palette.text.primary,
            },
            '&.selected:hover': {
              boxShadow: 'none',
              backgroundColor: `${ownerState.color}.main`,
            },
            '&.Mui-focusVisible': focusState(10),
          },
        }),
      outlined: ({ theme, ownerState }: any) =>
        theme.unstable_sx({
          px: 3,
          py: 0.5,
          alignItems: 'center',
          borderRadius: '30px',
          border: `1px solid ${theme.palette[ownerState.color].main}`,
          color: `${ownerState.color}.main`,
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: `${ownerState.color}.main`,
            color: theme.palette.getContrastText(
              theme.palette[ownerState.color].main,
            ),
            border: `1px solid ${theme.palette[ownerState.color].main}`,
          },
          '&.Mui-disabled': {
            border: `1px solid ${theme.palette.grey[700]}`,
            color: theme.palette.grey[700],
            opacity: 0.8,
          },
          '.buttonGroupContainer &': {
            px: 1,
            borderRadius: '10px',
            fontWeight: 700,
            color: `${ownerState.color}.main`,
            '&.selected': {
              backgroundColor: `${ownerState.color}.main`,
              color: '#ffffff',
            },
            '&.selected:hover': {
              boxShadow: 'none',
              backgroundColor: `${ownerState.color}.main`,
            },
            '&:hover': {
              color: '#ffffff',
            },
            '&.Mui-focusVisible': focusState(10),
          },
        }),
      text: ({ theme, ownerState }: any) =>
        theme.unstable_sx({
          fontWeight: 'bold',
          px: 0,
          color: `${ownerState.color}.main`,
          borderRadius: '2px',
          textDecoration: 'underline',
          '&.Mui-focusVisible': {
            textDecoration: 'underline',
            ...focusState(5),
          },
          '&:hover': {
            textDecoration: 'underline',
            background: 'none',
          },
          '&.customPlanRemove': {
            textDecoration: 'none',
            color: 'primary.main',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
        }),
      startIcon: ({ theme }: any) =>
        theme.unstable_sx({
          mr: 0.5,
          height: 'unset',
        }),
      endIcon: ({ theme }: any) =>
        theme.unstable_sx({
          ml: 0,
          height: 'unset',
        }),
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          '&.Mui-disabled': {
            opacity: 0.25,
          },
        }),
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ theme }: any) =>
        theme.unstable_sx({
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${toolTipBorderColor}`,
          p: 2,
          borderRadius: '6px',
          '& .MuiTooltip-arrow': {
            color: theme.palette.background.paper,
            '&::before': { border: `1px solid ${toolTipBorderColor}` },
          },
          '&>.MuiTypography-root:first-of-type': {
            mt: 0,
          },
        }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          // MUI overrides the top padding for some unknown reason.
          // See: https://github.com/mui/material-ui/issues/27851
          p: {
            xs: `${theme.spacing(2.5)}!important`,
            [mobileLayoutBreakpoint]: `${theme.spacing(3.5)}!important`,
          },
          // Remove extra padding from MuiContainer
          '.appWrapper': {
            p: 0,
          },
          // Also !important because of the above
          '.dialogHasButtons &': {
            pb: '0px !important',
          },
          '.dialogFullWidthContent &, .dialogSimple &': {
            padding: `0px!important`,
          },
          '.dialogFullWidthContent & .dialogContentWrapper': {
            padding: `0px!important`,
            borderRadius: '0px',
          },
          backgroundColor: 'background.default',
          '& .dialogContentWrapper': {
            backgroundColor: 'background.paper',
            p: 2.5,
            borderRadius: '8px',
          },
          '.dialogSimple & .dialogContentWrapper': {
            pt: 0,
          },
          [theme.breakpoints.down(mobileLayoutBreakpoint)]: {
            p: `${theme.spacing(2.5)}!important`,
          },
        }),
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          p: 3.5,
          backgroundColor: 'background.default',
          borderRadius: '0 0 10px 10px',
          flexDirection: { xs: 'column', sm: 'row' },
          '.dialogSimple &': {
            backgroundColor: 'background.paper',
            pt: 0,
          },
          '& .MuiButton-root:not(:first-of-type)': {
            mt: { xs: 2, sm: 0 },
          },
          [theme.breakpoints.down('sm')]: {
            p: 2.5,
          },
          // Override default left spacing
          '&>:not(:first-of-type)': {
            ml: 0,
          },
        }),
    },
  },
  MuiDialog: {
    styleOverrides: {
      container: ({ theme }: any) =>
        theme.unstable_sx({
          zIndex: 10,
          textAlign: 'left',
          [theme.breakpoints.down(mobileLayoutBreakpoint)]: {
            alignItems: 'flex-start',
          },
          '.topAligned &': {
            alignItems: 'flex-start',
          },
          '& .dialogHeader': {
            '& h2': {
              fontWeight: '600',
            },
            p: 2.5,
            backgroundColor: modalHeaderColor,
            color: 'background.paper',
            '& .MuiIconButton-root': {
              color: 'background.paper',
              '&.Mui-focusVisible': focusState(5),
            },
            '.dialogSimple &': {
              backgroundColor: 'background.paper',
              color: 'text.primary',
              '& .MuiIconButton-root': {
                color: 'text.primary',
              },
            },
            flexShrink: 0,
            borderRadius: '10px 10px 0 0',
          },
        }),
      paper: ({ theme }: any) =>
        theme.unstable_sx({
          borderRadius: '10px',
          [theme.breakpoints.up(mobileLayoutBreakpoint)]: {
            p: 0,
          },
          [theme.breakpoints.down(mobileLayoutBreakpoint)]: {
            p: 0,
            width: '100%',
            maxWidth: '100%',
            m: 1,
            maxHeight: '95%',
          },
          overflowY: 'auto !important',
        }),
      paperFullWidth: ({ theme }: any) =>
        theme.unstable_sx({
          maxWidth: '95%',
          borderRadius: '10px',
          [theme.breakpoints.down(mobileLayoutBreakpoint)]: {
            width: '100%',
            maxWidth: '100%',
            m: 1,
            maxHeight: '95%',
          },
        }),
    },
  },
  MuiCheckbox: {
    defaultProps: {
      color: 'secondary',
    },
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          px: 0,
          mx: 1,
          pb: 0,
          mb: 1,
        }),
    },
  },
  MuiRadio: {
    defaultProps: {
      color: 'secondary',
    },
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          m: 0.5,
          p: 0.5,
        }),
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
      color: 'secondary',
    },
    styleOverrides: {
      root: {
        padding: '2rem',
        borderRadius: '5px',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          lineHeight: '3em',
          '& input': {
            height: '3em',
          },
        }),
      formControl: {
        'label[data-shrink=false].MuiFormLabel-root ~ & ::-webkit-input-placeholder':
          {
            opacity: '0.5!important',
          },
      },
    },
  },
  MuiAccordion: {
    defaultProps: {
      disableGutters: true,
    },
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          boxShadow: 'none',
          border: `1px solid ${borderGreyColor}`,
          borderRadius: '8px',
          '&:last-of-type': {
            borderRadius: '8px',
          },
          mb: 2.5,
          p: 0,
          '&.Mui-expanded': {
            mb: 2.5,
            '&:last-of-type': {
              mb: 2.5,
            },
          },
          '&.MuiPaper-root': {
            backgroundColor: 'background.paper',
          },
          '&:before': { height: 0 },
        }),
    },
  },
  MuiCard: {
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          '&.MuiPaper-root': {
            backgroundColor: 'background.paper',
          },
          '.tabProgressBar &.MuiPaper-root': {
            backgroundColor: {
              xs: 'background.default',
              [mobileLayoutBreakpoint]: 'background.paper',
            },
          },
          '&.surfaceBox': {
            backgroundColor: 'background.paper',
            borderColor: 'secondary.main',
            borderStyle: 'solid',
            borderWidth: '1px',
            boxShadow: 'none',
            borderRadius: '12px',
            color: 'secondary.main',
            '&.disabled, & .Mui-disabled': {
              borderColor: theme.palette.grey[500],
              color: theme.palette.grey[500],
            },
            '&>.MuiCardActionArea-root.Mui-focusVisible': focusState(12),
          },
        }),
    },
  },
  MuiCardActionArea: {
    styleOverrides: {
      focusHighlight: ({ theme }: any) =>
        theme.unstable_sx({
          display: 'none',
        }),
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          minHeight: 20,
          p: 2.5,
        }),
      content: ({ theme }: any) =>
        theme.unstable_sx({
          '&.Mui-expanded': {
            m: 0,
          },
          m: 0,
        }),
    },
  },
  MuiCollapse: {
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          borderTop: `1px solid ${borderGreyColor}`,
          borderRadius: 0,
          mx: 2.5,
          mb: 2.5,
          '&.MuiCollapse-hidden': {
            border: 'none',
            m: 0,
          },
        }),
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          p: 0,
          pt: 2.5,
        }),
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          borderColor: borderGreyColor,
          '&.selectedBundle': {
            borderColor: 'primary.main',
          },
        }),
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          borderBottom: `1px solid ${borderGreyColor}`,
        }),
      indicator: ({ theme }: any) =>
        theme.unstable_sx({
          height: '4px',
          borderRadius: '20px',
        }),
      flexContainer: ({ theme }: any) =>
        theme.unstable_sx({
          justifyContent: {
            xs: 'center',
            [mobileLayoutBreakpoint]: 'flex-start',
          },
        }),
    },
  },
  MuiTab: {
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          color: 'text.primary',
          fontWeight: 400,
          fontSize: '1.3125rem',
          lineHeight: '1.575rem',
          p: 1.5,
          m: 1,
          mb: 0.5,
          '&.Mui-selected': {
            color: 'text.primary',
            fontWeight: 800,
          },
          '&.Mui-focusVisible': {
            zIndex: 500,
            overflow: 'visible',
            ...focusState(5),
          },
        }),
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          border: `1px solid ${borderGreyColor}`,
          '&:first-of-type': {
            borderLeft: 0,
          },
          '&:last-of-type': {
            borderRight: 0,
          },
          '&.shaded': {
            backgroundColor: lightGreyBackground,
          },
          '&.indent': {
            paddingLeft: 4,
          },
          '&.selected': {
            '& .bundleTitle': {
              color: 'primary.main',
            },
            borderLeft: `2px solid ${theme.palette.primary.main}`,
            borderRight: `2px solid ${theme.palette.primary.main}`,
            'thead tr:first-of-type &': {
              borderTop: `2px solid ${theme.palette.primary.main}`,
            },
            'tbody tr:last-of-type &': {
              borderBottom: `2px solid ${theme.palette.primary.main}`,
            },
          },
        }),
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          '&.mixedTotals > th': {
            borderBottomColor: 'transparent',
          },
          '&.mixedTotals > td': {
            borderBottomColor: 'transparent',
          },
          '&.mixed > th': {
            borderBottomColor: 'transparent',
            paddingLeft: 4,
          },
          '&.mixed > td': {
            borderTop: `2px dashed ${borderGreyColor}`,
          },
          '&.mixed .MuiTypography-root': {
            fontSize: '.75rem',
          },
          '&.mixed > td .bold': {
            fontWeight: 'normal',
          },
        }),
    },
  },
  MuiSlider: {
    styleOverrides: {
      root: ({ theme }: any) =>
        theme.unstable_sx({
          borderColor: borderGreyColor,
          '& .MuiSlider-mark': {
            borderColor: 'primary.main',
            marginBottom: '8px',
            height: 18,
            width: 18,
            borderRadius: 10,
            left: 0,
            backgroundColor: theme.palette.grey[400],
            transform: 'translate(-9px, -9px)',
            opacity: 1,
            '&.MuiSlider-markActive': {
              backgroundColor: 'primary.main',
            },
          },
          '& .MuiSlider-rail': {
            backgroundColor: theme.palette.grey[400],
            opacity: 1,
          },
        }),
    },
  },
};

// Mui - typography
export const typography: ThemeOptions['typography'] = {
  fontSize: 16,
  button: {
    textTransform: 'none',
  },
  body1: {
    fontSize: '1rem',
    lineHeight: 1.25,
  },
  body2: {
    fontSize: 12,
    lineHeight: 1.2,
  },
  h1: {
    fontSize: 48,
    '@media (max-width:600px)': {
      fontSize: 24,
    },
    lineHeight: 1.2,
    fontWeight: 'bold',
  },
  h2: {
    fontSize: 32,
    '@media (max-width:600px)': {
      fontSize: 24,
    },
    lineHeight: 1.2,
    fontWeight: 'bold',
  },
  h3: {
    fontSize: 21,
    lineHeight: 1.2,
    fontWeight: 'bold',
  },
  subtitle1: {
    fontSize: 21,
    '@media (max-width:600px)': {
      fontSize: 16,
    },
    lineHeight: 1.2,
  },
};

export const spacing = 7;
